import styled from "styled-components/macro";

const StyledError = styled.div`
  height: 1em;
  color: #d81919;
  font-size: 14px;
  letter-spacing: 0.2px;
  margin-left: 4px;
`;

const Error = ({ errors }) => (
  <StyledError>
    {errors?.type === "required" && "Veld niet ingevuld"}
  </StyledError>
);

export default Error;
