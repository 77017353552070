import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import AcceptForm from "./pages/AcceptForm";
import GlobalStyles from "./GlobalStyles";

const App = () => {

  const params = new URLSearchParams(window.location.search);
  const cus = params.get('cus')
  const est = params.get('est')
  cus && localStorage.setItem('cus', cus)
  est && localStorage.setItem('est', est)

  return (
    <div className="app">
      <GlobalStyles />
      <BrowserRouter>
        <Switch>
          <Route exact path="/">
            <a href='akkord'>Accept Form</a>
          </Route>
          <Route path="/akkoord">
            <AcceptForm />
          </Route>
          <Route path="*">
            <Redirect to="/akkoord" />
          </Route>
        </Switch>
      </BrowserRouter>
    </div>
  );
};

export default App;
