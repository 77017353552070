import "normalize.css";
import "./MuseoSans.css";
import { createGlobalStyle } from "styled-components/macro";

export default createGlobalStyle`
  body {
    height: 100%;
    width: 100%;
    
    font-family: "MuseoSans", sans-serif;
    letter-spacing: 0.1px;
  }

  *,
  *::before, 
  *::after {
    box-sizing: border-box;
    line-height: 1.6;
  }
`;
