import { useForm } from 'react-hook-form'
import styled from 'styled-components/macro'
import Button from '../../Button'
import { ButtonContainer, Description, Divider, StyledForm } from '../../Form'
import Header from '../../Header'
import Checkbox from '../../Checkbox'
import { Step3Confirmation } from './Step3Confirmation'
import { useHistory } from 'react-router-dom'


const StyledBox = styled.div`
  margin: auto;
  width: 218px;
  height: 81px;
  text-align: center;
  background-color: #FFDD00;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p:nth-child(2) {
    font-weight: bold;
    margin: 0;
  }

  p {
    margin: 0;
  }
  @media(max-width: 600px){
    margin-top: 15px;
    margin-bottom: 15px;
  }
`

const CheckboxWrapper = styled.div`
  margin-left: 70px;

  p {
    margin: 0;
    font-size: 14px;
    margin-left: 40px;
  }
  
  @media(max-width: 600px){
    margin: 0;
    margin-top: 15px;
    
    label{
      margin-left: 10px;
    }
  }
`

const Step3B = ({ state, onSubmit, parameters }) => {

  const { register, handleSubmit, errors } = useForm({
    defaultValues: state.data
  })

  const history = useHistory()
  const { step3Finished } = state.data

  return (
    <>
      {
        <StyledForm>
          {
            step3Finished ? <Step3Confirmation onSubmit={onSubmit} /> :
              <div>
                <Header title="Betaalwijze" />
                <Description>Voor het complete systeem (inclusief: installatie en x jaar productgarantie) betaal je:</Description>
                <StyledBox>
                  <p>eenmalig:</p>
                  <p>€5.679,80 (incl btw)</p>
                </StyledBox>
                <Description>Je ontvangt zometeen de pro forma factuur op emailadres [emailadres klant]. Dit is een voorlopige
                  factuur. Op de dag na installatie ontvang je de definitieve factuur. De definitieve factuur dient
                  binnen
                  twee dagen betaald te worden. </Description>

                <CheckboxWrapper>
                  <Checkbox
                    name="paymentCondition"
                    ref={register({ required: true })}
                    label="Ja, ik ga akkoord met deze betaalverplichting"
                    errors={errors} />
                </CheckboxWrapper>
                <Divider />
                <CheckboxWrapper>
                  <Checkbox
                    name="VATCondition"
                    ref={register({ required: false })}
                    label="Ja, ik wil graag gebruik maken van jullie gratis BTW-service*"
                    errors={errors} />
                  <p>*Wat houdt de BTW-service in? &nbsp;
                    <a
                      style={{ textDecoration: 'none', color: 'black' }}
                      rel="noreferrer" target="_blank"
                      href="https://www.solease.nl/zonnepanelen-kopen/extra-services/">
                      Klik hier</a>
                  </p>
                </CheckboxWrapper>
              </div>
          }
          <ButtonContainer>
            <Button
              onClick={(e) => {
                e.preventDefault()
                history.push('/akkoord/samenvatting')
              }}
            >
              Vorige stap
            </Button>
            <Button
              main
              onClick={handleSubmit((data) =>
                step3Finished ? onSubmit({ data, route: 'meterkast', finish: true, form: 1 }) : onSubmit({
                  data,
                  finish: true,
                  form: 3
                })
              )}
            >
              volgende
            </Button>
          </ButtonContainer>
        </StyledForm>
      }
    </>
  )
}


export default Step3B
