import styled, { css } from "styled-components/macro";

export const StyledForm = styled.form`
  height: 100%;
  position: relative;

  @media(max-width: 600px){
    width: 100%;
  }
`;

export const Fields = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
  grid-gap: 12px 24px;
  justify-items: center;
  margin: 0 48px;
  @media(max-width: 600px){
   display: block; 
    width: 100%;
    margin: auto;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 20px;
`;

export const Divider = styled.hr`
  border: none;
  grid-column: span 2;
  background-color: #5FBADE;
  margin: auto;
  width: 85%;
  height: 4px;
  margin-top: 10px;
`;

export const Description = styled.div`
  position: relative;
  font-size: 1.2em;
  grid-column: span 2;
  margin: 0 0 32px 0;
  margin-right: auto;

  ${(props) =>
    props.info &&
    css`
      p{
        font-weight: bold;
        margin: 0;
      }
      margin-top: 15px;
      font-size: 16px;
      margin-bottom: 9px;
      border: 3px solid #fff6bb;
      border-radius: 6px;
      background-color: #FFDD00;
      padding: 16px;
    `}

  ${(props) =>
    props.folded &&
    css`
      height: 95px;
      overflow:hidden;
    `}

  @media(max-width: 600px){
    font-size: 14px;
    margin-bottom: 2px;
  }
`;
