import { useForm } from 'react-hook-form'
import { ButtonContainer, Fields, StyledForm, Description } from '../Form'
import Input from '../Input'
import Button from '../Button'
import Header from '../Header'
import { useState } from 'react'
import styled from 'styled-components'

const NumberInputGroup = styled.div`
  width: 100%;
  display: flex;
  grid-column: span 2;

  div:nth-child(1) {
    width: 48.5%;
  }

  img {
    padding: 12px;
    width: 38px;
    height: 38px;
    background-color: #C4C4C4;
    border-radius: 50%;
    margin-top: auto;
    margin-bottom: 18px;
    margin-left: 5px;
    cursor: pointer;
  }
  @media(max-width: 600px){
    div:nth-child(1) {
      width: 100%;
    }
  }
`

const AddNumberField = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: auto;

  p {
    margin-left: 10px;
    font-weight: 300;
  }

  img {
    margin: 0;
    padding: 12px;
    width: 38px;
    height: 38px;
    background-color: #5FBADE;
    transform: rotate(45deg);
    border-radius: 50%;
    cursor: pointer;
  }

  @media(max-width: 600px){
    margin-bottom: 20px;
  }
`

const Step1 = ({ state, onSubmit, parameters, loginData }) => {
  const { register, handleSubmit, errors } = useForm({
    defaultValues: state.data
  })


  const closeIcon = require('../../../assets/images/close.svg').default

  const [telephones, setTelephones] = useState([null])

  const removeTelephoneField = (index) => {
    if (telephones.length > 1) {
      const telBuffer = [...telephones]
      telBuffer.splice(index, 1)
      setTelephones(telBuffer)
    }
  }
  const date = new Date()
  const month = date.getMonth() + 1 > 9 ? date.getMonth() + 1 : `0${date.getMonth() +1}`
  return (
    <>
      <Header title="Persoonsgegevens" />
      <StyledForm>
        <Fields>
          <Description>
            Je bent akkoord gegaan met de offerte. Om de overeenkomst te te voltooien willen we je vragen om nog een
            paar stappen te doorlopen. <br/><br/>
            Vul jouw gegevens aan met jouw geboortedatum en eventueel een extra telefoonnummer waarop we jou kunnen
            bereiken (bijvoorbeeld van je partner of familielid):
          </Description>
          <Input
            name="initials"
            ref={register({ required: true })}
            defaultValue={loginData.firstname || ''}
            label="Voorletters"
            errors={errors}
          />
          <Input
            name="lastname"
            ref={register({ required: true})}
            defaultValue={loginData.lastname || ''}
            label="Achternaam"
            errors={errors}
          />
          <Input
            name="birthdate"
            type="date"
            max={`${date.getFullYear() - 18}-${month}-${date.getDate()}`}
            ref={register({ required: true, valueAsDate: true })}
            label="Geboortedatum"
            errors={errors}
          />
          <Input
            name="email"
            ref={register({ required: true, pattern: /^.+@.+$/ })}
            defaultValue={loginData.email || ''}
            label="E-mailadres"
            errors={errors}
          />
          {
            telephones.map((tel, index) =>
              <NumberInputGroup key={index}>
                <Input
                  name={`phoneNumber${index + 1}`}
                  ref={register({ required: true })}
                  defaultValue={index === 0 && loginData.phone || ''}
                  label={`Telefoonnummer #${index + 1}`}
                  errors={errors}
                />
                <img
                  onClick={() => removeTelephoneField(index)}
                  alt="" src={closeIcon} />
              </NumberInputGroup>)
          }
          <AddNumberField onClick={() => setTelephones([...telephones, null])}>
            <img alt="" src={closeIcon} />
            <p>Telefoonnummer toevoegen</p>
          </AddNumberField>
        </Fields>
      </StyledForm>
      <ButtonContainer>
        <Button
          main
          onClick={handleSubmit((data) =>
            onSubmit({ data, route: 'samenvatting', postRoute: 'submit/info'})
          )}
        >
          Volgende stap
        </Button>
      </ButtonContainer>
    </>
  )
}

export default Step1
