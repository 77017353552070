import { useForm } from 'react-hook-form'
import Button from '../Button'
import {
  ButtonContainer
} from '../Form'
import Header from '../Header'
import styled from 'styled-components'
import { useState } from 'react'
import { WarningOverlay } from '../WarningOverlay'
import { useHistory } from 'react-router-dom'

const Section = styled.div`
  position: relative;
  margin: auto;
  margin-bottom: 70px;
  width: 100%;
  max-width: 780px;
  padding: 5px 20px;
  border: 3px solid #FFDD00;
  border-radius: 10px;
  box-shadow: 0px 10px 11px -4px rgba(0, 0, 0, 0.48);

  p:nth-child(1) {
    font-weight: bold;
  }

  img {
    position: absolute;
    left: 50%;
    bottom: -57px;
  }
`

const Step5 = ({ state, onSubmit, parameters, rent, survey }) => {

  const {  formState } = useForm({
    defaultValues: state.data
  })
  const [warningScreen, setWarningScreen] = useState(false)

  const arrowIcon = require('../../../assets/images/arrow.svg').default
  const history = useHistory()

  const noSurveyRent = {
    'Bestelling van je zonnestroomsysteem': 'Als we de meterkast foto’s hebben ontvangen en beoordeeld, bestellen we het zonnestroomsysteem voor jouw dak. ' +
      'De installateur zal dan binnen twee weken contact met je opnemen voor het plannen van de installatiedatum. Op dit moment is de gemiddelde tijd tot installatie 8-10 weken.',
    'Installatie': 'De installatie wordt uitgevoerd in één dag(deel)',
    'Start huurbetaling': 'Je gaat pas betalen vanaf de dag dat het zonnestroomsysteem daadwerkelijk stroom opwekt. Meestal is dit de dag van installatie.'
  }

  const surveyRent = {
    'Meterkast foto’s': 'Als we de meterkast foto’s hebben ontvangen en beoordeeld, bestellen we het zonnestroomsysteem voor jouw dak.',
    'Schouw': 'Een mogelijk onderdeel van jouw offerte is een schouw. Dit betekent dat de installateur nu eerst zal langskomen om je dak te bekijken zodat jullie op de dag van installatie niet voor verrassingen komen te staan. In sommige gevallen komt het voor dat er meerwerkkosten uit naar voren komen. We sturen je dan een nieuwe offerte, waarbij dit bedrag in de maandhuur wordt verrekend.',
    'Bestelling van je zonnestroomsysteem': 'Als de schouw heeft plaatsgevonden, wordt het schouwrapport door ons is beoordeeld. Eventuele wijzigingen en bijzonderheden worden dan met jou besproken. Als dit allemaal rond is, gaan we het zonnestroomsysteem voor jouw dak bestellen. De installateur zal dan binnen twee weken contact opnemen voor het plannen van de installatiedatum.  Op dit moment is de gemiddelde tijd tot installatie 8-10 weken.',
    'Installatie ': 'De installatie wordt uitgevoerd in één dag(deel) ',
    'Start huurbetaling': 'Je gaat pas betalen vanaf de dag dat het zonnestroomsysteem daadwerkelijk stroom opwekt. Meestal is dit de dag van installatie.'
  }

  const noSurveyNoRent = {
    'Bestelling van je zonnestroomsysteem': 'Als we de meterkast foto’s hebben ontvangen en beoordeeld, bestellen we het zonnestroomsysteem voor jouw dak. De installateur zal dan binnen twee weken contact met je opnemen voor het plannen van de installatiedatum. Op dit moment is de gemiddelde tijd tot installatie 8-10 weken.',
    'Installatie': 'De installatie wordt uitgevoerd in één dag(deel)',
    'Betaling koopfactuur': 'Je ontvangt de definitieve factuur pas de dag nadat het zonnestroomsysteem daadwerkelijk stroom opwekt. Deze dient binnen 2 dagen vanaf facturatiedatum te worden voldaan.'
  }

  const surveyNoRent = {
    'Meterkast foto’s': 'Als we de meterkast foto’s hebben ontvangen en beoordeeld, bestellen we het zonnestroomsysteem voor jouw dak.',
    'Schouw': 'Een mogelijk onderdeel van jouw offerte is een schouw. Dit betekent dat de installateur nu eerst zal langskomen om je dak te bekijken zodat jullie op de dag van installatie niet voor verrassingen komen te staan. In sommige gevallen komt het voor dat er meerwerkkosten uit naar voren komen. We sturen je dan een nieuwe offerte, waarbij dit bedrag in de maandhuur wordt verrekend.',
    'Bestelling van je zonnestroomsysteem': 'Als de schouw heeft plaatsgevonden, wordt het schouwrapport door ons is beoordeeld. Eventuele wijzigingen en bijzonderheden worden dan met jou besproken. Als dit allemaal rond is, gaan we het zonnestroomsysteem voor jouw dak bestellen. De installateur zal dan binnen twee weken contact opnemen voor het plannen van de installatiedatum.  Op dit moment is de gemiddelde tijd tot installatie 8-10 weken.',
    'Installatie': 'De installatie wordt uitgevoerd in één dag(deel)',
    'Betaling koopfactuur': 'Je ontvangt de definitieve factuur pas de dag nadat het zonnestroomsysteem daadwerkelijk stroom opwekt. Deze dient binnen 2 dagen vanaf facturatiedatum te worden voldaan.'
  }

  return (
    <>
      {
        warningScreen &&
        <WarningOverlay onCancel={() => setWarningScreen(false)}
                        onConfirm={() => history.push('/akkoord/meterkast')} />
      }
      <Header title="Hoe nu verder?" />

      {
        rent && survey ?
          Object.keys(surveyRent).map((title, index) => <Section key={index}>
            <p>{title}</p>
            <p>{surveyRent[title]}</p>
            {index + 1 < Object.keys(surveyRent).length && <img alt='' src={arrowIcon}/>}
          </Section>) :
          rent && !survey ?
            Object.keys(noSurveyRent).map((title, index) => <Section key={index}>
              <p>{title}</p>
              <p>{noSurveyRent[title]}</p>
              {index + 1 < Object.keys(noSurveyRent).length && <img alt='' src={arrowIcon}/>}
            </Section>) :
            !rent && survey ?
              Object.keys(surveyNoRent).map((title, index) => <Section key={index}>
                <p>{title}</p>
                <p>{surveyNoRent[title]}</p>
                {index + 1 < Object.keys(surveyNoRent).length && <img alt='' src={arrowIcon}/>}
              </Section>) :
              !rent && !survey &&
                Object.keys(noSurveyNoRent).map((title, index) => <Section key={index}>
                  <p>{title}</p>
                  <p>{noSurveyNoRent[title]}</p>
                  {index + 1 < Object.keys(noSurveyNoRent).length && <img alt='' src={arrowIcon}/>}
                </Section>)

      }

      <ButtonContainer>
        <Button
          onClick={(e) => {
            e.preventDefault()
            setWarningScreen(true)
          }}
        >
          Vorige stap
        </Button>
        <Button
          main
          disabled={formState.isSubmitting}
          onClick={(e)=>{
            e.preventDefault()
            history.push('/akkorod/verzonden')
          }}
        >
          Klaar
        </Button>
      </ButtonContainer>
    </>
  )
}

export default Step5
