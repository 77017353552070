import { forwardRef } from "react";
import styled from "styled-components/macro";
import Error from "./Error";
import { StyledField } from "./Input";

const StyledRadioGroup = styled.fieldset`
  border: none;
  padding: 10px;
`;

const OptionContainer = styled.div`
  padding: 6px;
  label, input{
    display: flex;
    align-items: center;
  }
`;

const Fill = styled.div`
  display: inline-block;
  position: relative;
  border: 2px solid #9e9e9e;
  min-width: 18px;
  height: 18px;
  left: 0;
  border-radius: 50%;
  vertical-align: middle;

  &::after {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-radius: 50%;
    background-color: #ffdb00;
    opacity: 0;
    left: 50%;
    top: 50%;
    position: absolute;
    transition: all 100ms ease-in-out;
  }
`;

const Option = styled.input`
  position: absolute;
  visibility: hidden;
  display: none;

  &:checked + ${Fill} {
    &::after {
      width: 10px;
      height: 10px;
      opacity: 1;
      left: 2px;
      top: 2px;
    }
  }
`;

const StyledLabel = styled.label`
  cursor: pointer;
  display: flex;
  gap: 8px;
`;

const StyledLegend = styled.legend``;

const RadioGroup = forwardRef(({ label, name, errors, options }, ref) => {
  return (
    <StyledField>
      <StyledRadioGroup>
        <StyledLegend>{label}</StyledLegend>
        {options.map(({ label: optionLabel, value }) => {
          return (
            <OptionContainer key={value}>
              <StyledLabel htmlFor={`${name}${value}`}>
                <Option
                  id={`${name}${value}`}
                  name={name}
                  type="radio"
                  value={value}
                  ref={ref}
                />
                <Fill />
                {optionLabel}
              </StyledLabel>
            </OptionContainer>
          );
        })}
        <Error errors={errors?.[name]} />
      </StyledRadioGroup>
    </StyledField>
  );
});

export default RadioGroup;
