import styled from "styled-components/macro";
import "../../EnzoOT.css";

const Title = styled.h1`
  font-family: "EnzoOT", sans-serif;
  font-weight: 100;
  font-size: 36px;
  color: #2d2d2d;
  margin: 0;
  text-transform: uppercase;
`;

const StyledHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 48px;

  @media(max-width: 600px){
    margin-bottom: 21px;
    text-align: center;
    ${Title}{
      font-size: 24px;
    }
  }
`;

const Header = ({ title }) => (
  <StyledHeader>
    <Title>{title}</Title>
  </StyledHeader>
);

export default Header;
