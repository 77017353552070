import { ButtonContainer, StyledForm, Description} from '../Form'
import Header from '../Header'
import styled from 'styled-components'
import Button from '../Button'
import { useForm } from 'react-hook-form'

const Wrapper = styled.div`
  ${StyledForm} {
    width: 90%;
    margin: auto;
  }
`

const Block = styled.div`
  display: flex;
  margin-bottom: 30px;
  font-size: 18px;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 150px;
  }

  a {
    text-decoration: none;
    color: black;
    font-family: sans-serif;
    font-weight: bold;
  }
  
  @media(max-width: 600px){
    flex-direction: column;
    
    p{
      font-size: 14px;
      margin-bottom: 0;
    }
  }
`

const PhotoPostpone = ({ state, onSubmit }) => {

  const linkIcon = require('../../../assets/images/link.svg').default
  const emailIcon = require('../../../assets/images/email.svg').default
  const whatsappIcon = require('../../../assets/images/whatsapp.svg').default

  const { handleSubmit } = useForm({
    defaultValues: state.data
  })

  return (
    <Wrapper>
      <StyledForm>
        <Header title="Meterkast foto’s later uploaden" />

        <Description>Je hebt aangegeven nu geen meterkast foto’s te kunnen uploaden, dat is geen enkel probleem. </Description>

        <Description> Je ontvangt een mail met een herinnering om deze alsnog op te sturen. Dit kan op de volgende
          manieren:</Description>

        <Block>
          <div><img alt="" src={linkIcon} /></div>
          <p><b>Link</b> : In de herinneringsmail ontvang je een link naar dit formulier, op de stap waar je gebleven
            was. Hier
            kun je de foto’s uploaden. Als je dit met je mobiel opent kan je de foto’s maken en direct uploaden.</p>
        </Block>

        <Block>
          <div><img alt="" src={emailIcon} /></div>
          <p><b>Email</b> : Als je foto’s per email wilt verzenden, ontvangen we deze graag op emailadres&nbsp;<a
            href="mailto:info@solease.nl">info@solease.nl</a>
            . Graag onder vermelding van je naam en klantnummer [K-nummer Custom ID]. </p>
        </Block>

        <Block>
          <div><img alt="" src={whatsappIcon} /></div>
          <p><b>Whatsapp</b> : De foto’s kunnen per Whatsapp verstuurd worden naar telefoonnummer&nbsp;<a
            href="tel:0640971819">0640971819< /a>. Graag
            onder
            vermelding van je naam en klantnummer [K-nummer Custom ID]. </p>
        </Block>
        <ButtonContainer>
          <Button onClick={handleSubmit((data) =>
            onSubmit({ data, route: 'meterkast' })
          )}>VORIGE STAP</Button>
          <Button
            main
            onClick={handleSubmit((data) =>
              onSubmit({ data, route: 'afronding', finish: true, form: 1 })
            )}
          >
            volgende
          </Button>
        </ButtonContainer>
      </StyledForm>
    </Wrapper>
  )
}


export default PhotoPostpone
