import styled from 'styled-components'
import { ButtonContainer } from './Form'
import Button from './Button'
import Header from './Header'
import "../../EnzoOT.css";


const Wrapper = styled.div`
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, .3);
`

const Content = styled.div`
  margin: auto;
  margin-top: 200px;
  background-color: white;
  width: 500px;
  height: 300px;
  text-align: center;
  padding-top: 20px;
  
  div:first-child{
    margin-bottom: 0 !important;
    h1{
      font-family: "EnzoOT", sans-serif;
      font-weight: bold; 
    }
  }
  
  p{
    font-weight: bold;
    margin: 0;
  }
  
  div:last-child{
    padding: 0 50px;
    margin-top: 40px;
    button{
      margin-top: auto;
      margin-bottom: 0;
    }
  }
  
  @media(max-width: 600px){
    width: 90%;
    height: 250px;
    button{
      width: 100px;
    }
  }
`
const WarningOverlay = (props) => {

  return (
    <Wrapper>
      <Content>
        <Header title='Let op' />
        <p>De stap die je probeert te openen is reeds voltooid.</p>
        <p>Weet je zeker dat je terug wilt gaan?</p>
        <ButtonContainer>
          <Button
            onClick={props.onConfirm}>
            Ja</Button>
          <Button
            onClick={props.onCancel}
            main>
            Nee</Button>
        </ButtonContainer>
      </Content>
    </Wrapper>
  )
}

export { WarningOverlay }
