import { NavLink } from "react-router-dom";
import styled from "styled-components/macro";
import '../../EnzoOT.css'

const Text = styled.h2`
  font-family: "EnzoOT", sans-serif;
  position: relative;
  font-weight: 100;
  font-size: 2em;
  line-height: 1.8em;
  cursor: pointer;
  margin: 0;
  color: #7e7e7e;
  user-select: none;
`;

const Line = styled.hr`
  height: 4px;
  width: 140px;
  border: 0;
  padding: 0;
  margin-top: 10px;
  border-radius: 2px;
  background-color: #e1e1e1;
`;

const StyledNavigationItem = styled(NavLink)`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  z-index: 1;
  text-decoration: none;
  
  &:after{
    box-sizing: content-box;
    content: '';
    width: 25px;
    height: 25px;
    border-radius: 25px;
    position: absolute;
    right: 18.05px;
    bottom: 35px;
    background-color: #FFDB00;
    transition: background-color .2s;
  }

  &.active {
    ${Text} {
      color: #000;
      transform: translateY(-2px);
      text-shadow: 3px 2px 1px #eafbff;
    }

    ${Line} {
      background-color: #5FBADE;
      box-shadow: 0px 1px 0 rgba(0, 0, 0, 0.2);
      transform: translateY(-1px);
    }
    
    &:after{
      border: 3px solid white;
      background-color: #5FBADE;
      right: 16px;
      box-shadow: 0px 10px 11px -4px rgba(0,0,0,0.48);
    }
  }
  
  @media(max-width: 1300px){
    width: 20%;
    ${Line}, ${Text}{
      display: none;
    }
    &:after{
      left: 50%;
      transform: translateX(-50%);
    }
    &.active{
      &:after{
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
`;

const NavigationItem = ({ name, route }) => {
  return (
    <StyledNavigationItem to={route}>
      <Text>{name}</Text>
      <Line />
    </StyledNavigationItem>
  );
};

export default NavigationItem;
