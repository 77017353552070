import { useForm } from 'react-hook-form'
import Button from '../../Button'
import { ButtonContainer, Description, StyledForm } from '../../Form'
import Header from '../../Header'
import styled from "styled-components/macro";
import Dropdown from 'react-dropdown'
import 'react-dropdown/style.css'
import { useEffect, useState } from 'react'
import { Step3Confirmation } from './Step3Confirmation'
import { useHistory } from 'react-router-dom'

const StyledDropdown = styled(Dropdown)`
  margin-bottom: 66px;
`

const Step3A = ({ state, onSubmit, parameters, token, price, estimateNumber, setLoading }) => {

  const { handleSubmit } = useForm({
    defaultValues: state.data
  })

  const history = useHistory()
  const [bankList, setBankList] = useState([])
  const [selectedBank, setSelectedBank] = useState()


  const { step3Finished } = state.data

  const getBankList = () => {
    fetch('https://proxy.staging.solease.nl/test/paf/mandate/directory', {
      method: 'GET',
      headers: {
        'Authorization': token
      }
    }).then(response => response.json())
      .then(data => setBankList(data.debtorBanks.map(bank => ({
        label: bank.debtorBankName,
        value: bank.debtorBankId
      }))))
  }

  useEffect(getBankList, [token])

  const initiateMandate = () => {
    setLoading()
    fetch('https://proxy.staging.solease.nl/test/paf/mandate/new', {
      method: 'POST',
      headers: {
        'Authorization': token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(
        {
          order: estimateNumber,
          bankCode: selectedBank,
          returnUrl: "http://feat-marketing-re-zds3ty.app.solease.nl/akkoord/"
        }
      )
    }).then(response => response.json())
      .then(data => window.open(data.issuerAuthenticationUrl,"_self"))
  }

  return (
    <>
      <StyledForm>
        {
          step3Finished ? <Step3Confirmation onSubmit={onSubmit} /> :
            <div>
              <Header title="Incassomachtiging" />
              <Description>Om de huur van de zonnepanelen maandelijks van jouw bankrekening af te kunnen schrijven, hebben we een digitale machtiging van je nodig.
                <br/> <br/>
                Selecteer hieronder de juiste bank: </Description>
              <div>
                <StyledDropdown options={bankList} onChange={(option) => setSelectedBank(option.value)}
                          placeholder="Select an option" />
              </div>
              <Description>Klik op Ga door naar machtiging om de machtiging te voltooien via je bankomgeving. </Description>
            </div>
        }
      </StyledForm>
      <ButtonContainer>
        <Button
          onClick={()=>history.push('/akkoord/samenvatting')}
        >
          Vorige stap
        </Button>
        <Button
          main
          onClick={()=>{
            !state.data.step3Finished ? initiateMandate() : handleSubmit((data)=> onSubmit({ data, route: 'meterkast', finish: true, form: 1 }))
          }}
        >
          Ga door naar Machtiging
        </Button>
      </ButtonContainer>
    </>
  )
}

export default Step3A
