import { useForm } from 'react-hook-form'
import Button from '../Button'
import styled from 'styled-components'
import { ButtonContainer, Fields, StyledForm } from '../Form'
import Header from '../Header'
import { useState } from 'react'
import { WarningOverlay } from '../WarningOverlay'
import Checkbox from '../Checkbox'
import { useHistory } from 'react-router-dom'

const Wrapper = styled.div`

  @media (max-width: 600px) {
    label {
      font-size: 14px;
    }
  }
`

const StyledBox = styled.div`
  margin: auto;
  width: 218px;
  height: 81px;
  text-align: center;
  background-color: #FFDD00;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p:nth-child(2) {
    font-weight: bold;
    margin: 0;
  }

  p {
    margin: 0;
  }

  @media (max-width: 600px) {
    margin-top: 15px;
    margin-bottom: 15px;
  }
`

const DataBox = styled.div`
  margin: auto;
  background-color: #FFDD00;
  display: flex;
  justify-content: space-between;
  width: 777px;
  margin-bottom: 25px;
  margin-top: 50px;
  max-height: 251px;

  div {
    position: relative;

    p:nth-child(1) {
      font-size: 24px;
      position: absolute;
      top: -40px;
      margin: 0;
      right: 50%;
      transform: translate(50%, 0);
      text-align: center;
      width: 100%;
    }

    &:nth-child(1) {
      padding: 20px 40px;
      width: 100%;
    }
  }

  img {
    height: 100%;
    width: 380px;
  }

  p {
    margin-bottom: 10px;

    span {
      float: right;
    }
  }

  div:nth-child(2) {
    text-align: center;
  }

  @media (max-width: 950px) {
    flex-direction: column;
    max-height: unset;
    justify-content: space-between;
    width: 100%;
    img {
      width: 100%;
    }
  }

  @media (max-width: 600px) {
    width: 100%;
    div:nth-child(1) {
      p:nth-child(1) {
        font-size: 18px;
      }

      p {
        font-size: 14px;
      }
    }

    div:nth-child(2) {
      p {
        font-size: 16px;
        margin: 0;
        position: initial;
        transform: none;
      }

      width: 100%;
    }
  }
`

const Text = styled.p`
  font-size: 19px;
  text-align: left;
  margin: auto;
  width: 777px;

  @media (max-width: 1300px) {
    width: 100%;
    margin-bottom: 10px;
  }
`

const Step2 = ({ state, onSubmit, parameters, data }) => {
  const { handleSubmit, register, errors } = useForm({
    defaultValues: state.data
  })

  const history = useHistory()

  const [warningScreen, setWarningScreen] = useState(false)

  return (
    <Wrapper>
      {
        warningScreen &&
        <WarningOverlay onCancel={() => setWarningScreen(false)}
                        onConfirm={() => history.push('/akkoord/gegevens')} />
      }
      <Header title="Samenvatting offerte" />
      <StyledForm>
        <Text>Voor het complete systeem (inclusief: installatie, reparatie, onderhoud, verzekering en monitoring) betaal
          je:
        </Text>
        {
          data.rent ?
            <StyledBox>
              <p><b>€{data.price}</b></p>
              <p>per maand</p>
            </StyledBox> :
            <StyledBox>
              <p>eenmalig:</p>
              <p>€{data.price} (incl btw)</p>
            </StyledBox>
        }
        <DataBox>
          <div>
            <p>SYSTEEM SPECIFICATIES:</p>
            <p>OFFERTE NUMMER: <span>{data.estimateNumber}</span></p>
            <p>AANTAL PANELEN: <span>{data.moduleCount}</span></p>
            <p>TYPE PANELEN: <span>{data.inverter}</span></p>
            <p>TYPE OMVORMER: <span>SE3000H</span></p>
            <p>OPTIMIZERS: <span>{data.modulePower}</span></p>
          </div>
          <div>
            <p>FOTO LEGPLAN:</p>
            <img alt=""
                 src={`https://proxy.staging.solease.nl/test/paf${data.layoutUrl}`} />
          </div>
        </DataBox>
        <Text>Wijkt de samenstelling van bovenstaand zonnestroomsysteem af van de offerte waarmee je akkoord
          bent gegaan? Geen zorgen! Neem dan contact op met jouw solar adviseur via info@solease.nl of 030-320 02
          37.</Text>
        <Fields>
          <Checkbox
            name="termsAndConditiions"
            ref={register({ required: true })}
            label="Ik ga akkoord met bovenstaande offerte en met de  "
            link={<a style={{ textDecoration: 'none', color: '#5FBADE', fontWeight: 'bold' }} href="#1">algemene
              voorwaarden</a>} //eslint-disable-line jsx-a11y/anchor-is-valid
            errors={errors}
          />
        </Fields>
        <ButtonContainer>
          <Button
            onClick={(e) => {
              e.preventDefault()
              setWarningScreen(true)
            }}
          >
            Vorige stap
          </Button>
          <Button
            main
            onClick={handleSubmit((data) =>
              onSubmit({ data, route: 'betaalwijze', postRoute: 'submit/summary' })
            )}
          >
            Volgende stap
          </Button>
        </ButtonContainer>
      </StyledForm>
    </Wrapper>
  )
}


export default Step2
