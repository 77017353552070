import styled, { css } from "styled-components/macro";
import { forwardRef } from "react";
import Error from "./Error";

export const StyledField = styled.div`
  position: relative;
  grid-column: ${({ wide }) => wide && "span 2"};
  width: 100%;
`;

export const StyledTextfield = css`
  ${(props) =>
    props.type !== "file" &&
    css`
      transition: all 0.15s ease-in-out;
      padding: 10px 12px;
      border: 1px solid #9e9e9e;
      border-radius: 4px;
      font-family: inherit;
      font-size: 18px;
      background: #fff;
      width: 100%;
      outline: none;
      height: 46px;

      &:focus {
        border-color: #4e4e4e;
        box-shadow: 0 0 1px 1px #4e4e4e;
      }
    `}
  @media(max-width: 600px){
  font-size: 14px;
}
`;

const StyledInput = styled.input`
  ${StyledTextfield}
`;

export const StyledInputLabel = styled.label`
  transition: all 0.25s ease-in-out;
  user-select: none;
  font-weight: bold;
  color: #696969;
  margin-left: 2px;
`;

export const StyledInputSubLabel = styled.span`
  transition: all 0.25s ease-in-out;
  user-select: none;
  color: #696969;
  margin-left: 4px;
`;

const Input = forwardRef(
  ({ label, sublabel, placeholder, name, errors, ...inputProps }, ref) => {
    return (
      <StyledField>
        <StyledInputLabel htmlFor={name}>{label}</StyledInputLabel>
        <StyledInputSubLabel>{sublabel}</StyledInputSubLabel>
        <StyledInput
          ref={ref}
          label={label}
          name={name}
          placeholder={placeholder}
          id={name}
          {...inputProps}
        />
        <Error errors={errors?.[name]} />
      </StyledField>
    );
  }
);

export default Input;
