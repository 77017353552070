import styled from "styled-components/macro";

const StyledButton = styled.button.attrs(({ type }) => ({
  type: type || "submit",
}))`
  border: 3px solid ${(props) => (props.main ? "#5fbade" : "#595957")};
  border-radius: 2px;
  background: ${(props) => (props.main ? "#5fbade" : "#595957")};
  font-weight: bold;
  color: #fff;
  font-size: 24px;
  max-width: 240px;
  padding: 16px 22px;
  cursor: pointer;
  user-select: none;
  transition: all 0.06s;
  position: relative;
  display: flex;
  text-transform: uppercase;
  padding-left: ${(props) => props.isLoading && "64px"};

  &:hover {
    border-color: ${(props) => (props.main ? "#3993cc" : "#3f3f3e")};
    background: ${(props) => (props.main ? "#3993cc" : "#3f3f3e")};
  }

  &:active {
    background: ${(props) => (props.main ? "#2885bf" : "#2d2d2d")};
    border-color: ${(props) => (props.main ? "#2885bf" : "#2d2d2d")};
    box-shadow: 1px 1px 0px 0 #000;
    transform: translate(1px, 1px);
  }

  &:only-child {
    margin-left: auto;
  }
  
  @media(max-width: 600px){
    width: 40%;
    margin-right: 10px;
    margin-left: 10px;
    white-space: normal;
    word-wrap: break-word;
    padding: 5px;
    align-items: center;
    justify-content: center;
  }
`;

const Spinner = styled.div`
  position: absolute;
  animation: loading 1s infinite linear;
  display: ${(props) => !props.isLoading && "none"};
  left: 16px;

  &:after {
    content: " ";
    display: block;
    width: 26px;
    height: 26px;
    margin: 0 8px;
    border-radius: 50%;
    border: 4px solid #fff;
    border-left-color: transparent;
  }

  @keyframes loading {
    0% {
      animation-timing-function: cubic-bezier(0.85, 0.18, 0.15, 0.75);
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const Button = (props) => (
  <StyledButton {...props}>
    <Spinner isLoading={props.isLoading}></Spinner>
    {props.children}
  </StyledButton>
);

export default Button;
