import { useForm } from 'react-hook-form'
import Button from '../Button'
import styled from 'styled-components'
import {
  Description,
  Divider,
  Fields,
  StyledForm
} from '../Form'
import Header from '../Header'
import Input from '../Input'
import RadioGroup from '../RadioGroup'
import { Lightbox } from 'react-modal-image'
import { useState } from 'react'
import { FileInput } from '../FileInput'
import { useHistory } from 'react-router-dom'

const Wrapper = styled.div`
  @media(max-width: 600px){
    ${Divider}{
      margin-top: 58px;
      margin-bottom: 32px;
      width: 100%;
      height: 10px;
    }
  }
`

const InputFileWrapper = styled.div`
  margin: auto;
  width: 90%;
`

const ImageInputGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;

  img:nth-child(2) {
    height: 146px;
    cursor: pointer;
  }
  
  @media(max-width: 600px){

    img:nth-child(2) {
      width: 72px;
    }
  }
`

const RouterField = styled.div`
  margin-left: auto;
  margin-top: -25px;
  font-size: 12px;
  text-align: center;

  img {
    cursor: pointer;
  }
  @media(max-width: 600px){
    display: none;
  }
`

const ButtonsContainer = styled.div`
  max-width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;

  button {
    &:nth-child(1) {
      height: 50%;
      width: 150px;
    }

    display: flex;
    justify-content: center;
    align-items: center;

    height: 90px;
    font-size: 18px;
    margin: 0;
    width: 30%;
  }
  @media(max-width: 600px){
    flex-direction: column;
    align-items: flex-start;
    button{
      &:nth-child(1){
        height: 57px !important;
      }
      width: 100%;
      margin-bottom: 12px;
    }
  }
`

const FoldButton = styled('div')`
  position: relative;
  background-color: #FFDD00;
  width: 165px;
  height: 41px;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
  margin-bottom: 40px;
  font-size: 12px;

  p {
    position: absolute;
    left: -75px;
  }
`

const Text = styled.p`
  margin: auto;
  width: 90%;
  margin-bottom: 20px;
`

const FileLabel = styled.p`
  margin-bottom: 0;
  @media(max-width: 600px){
    text-align: center;
  }
`

const Step4 = ({ state, onSubmit, parameters }) => {

  const { register, handleSubmit, errors, setValue, formState } = useForm({
    defaultValues: state.data
  })

  const [modal, setModal] = useState()
  const history = useHistory()

  const [meterkastImages, setMeterkastImages] = useState([])
  const [meterImages, setMeterImages] = useState([])
  const [fuseImages, setFuseImages] = useState([])
  const [descFolded, setDescFolded] = useState(true)

  const meterkastImage = require('../../../assets/images/meterkast_preview.jpg').default
  const meterImage = require('../../../assets/images/meter_preview.jpg').default
  const fuseImage = require('../../../assets/images/hoofdschakelaar_preview.jpg').default
  const routerImage = require('../../../assets/images/router.png').default
  const foldArrow = require('../../../assets/images/foldArrow.svg').default

  return (
    <Wrapper>
      {
        modal && <Lightbox
          medium={modal}
          hideDownload={true}
          hideZoom={true}
          onClose={() => setModal(undefined)}
        />

      }
      <Header title="Meterkast informatie" />
      <StyledForm>
        <Text>Ter voorbereiding van de installatie van de zonnepanelen ontvangen wij graag een foto van de meterkast,
          (smart) meter en hoofdschakelaar. We kunnen dan beoordelen of je meterkast geschikt is voor
          installatie. </Text>
        <InputFileWrapper>

          <FileLabel>Upload foto <b>meterkast</b> overzichtsfoto</FileLabel>
          <ImageInputGroup>
            <FileInput   passImage={(image, type) => setMeterkastImages([...meterkastImages, {
                         type: 'meterkast',
                         extension: type,
                         content: image
                       }])}
                       activatePopup={(image) => setModal(image)} />
            <img alt="" src={meterkastImage} onClick={() => setModal(meterkastImage)} />
          </ImageInputGroup>

          <FileLabel>Upload foto <b>meter</b></FileLabel>
          <ImageInputGroup>
            <FileInput   passImage={(image, type) => setMeterImages([...meterImages, {
                         type: 'meter',
                         extension: type,
                         content: image
                       }])}
                       activatePopup={(image) => setModal(image)} />
            <img alt="" src={meterImage} onClick={() => setModal(meterImage)} />
          </ImageInputGroup>

          <FileLabel>Upload foto <b>hoofdschakelaar</b></FileLabel>
          <ImageInputGroup>
            <FileInput   passImage={(image, type) => setFuseImages([...fuseImages, {
                         type: 'hoofdschakelaar',
                         extension: type,
                         content: image
                       }])}
                       activatePopup={(image) => setModal(image)} />
            <img alt="" src={fuseImage} onClick={() => setModal(fuseImage)} />
          </ImageInputGroup>

        </InputFileWrapper>
        <Fields>
          <Divider />
          <RadioGroup
            name="router_location"
            ref={register({ required: false })}
            label="Waar staat je internet router/ modem?"
            errors={errors}
            options={[
              {
                label: 'In de meterkast',
                value: 'meterCupboard'
              },
              {
                label: (
                  <Input
                    name="routerLocationOther"
                    ref={register({
                      required: state?.data?.routerLocation === 'other'
                    })}
                    onFocus={() => setValue('routerLocation', 'other')}
                    style={{
                      marginTop: '-10px',
                      width: '40%',
                      height: '32px',
                      paddingLeft: '8px',
                      fontSize: '15px'
                    }}
                    placeholder="Anders"
                  />
                ),
                value: 'other'
              }
            ]}
          /> <br />
          <RadioGroup
            name="router_freeport"
            ref={register({ required: false })}
            label="Heb je een vrije poort op je internet router/ modem waar de omvormer op aangesloten kan worden?"
            errors={errors}
            options={[
              { label: 'Ja', value: '1' },
              { label: 'Nee', value: '3' }
            ]}
          />
          <RouterField>
            <p>voorbeeld foto routerpoort</p>
            <img
              onClick={() => setModal(routerImage)}
              alt="" src={routerImage} />
          </RouterField>
        </Fields>
        <Description info folded={descFolded}>
          <p>Waarom heb je internet nodig voor je zonnestroomsysteem?</p>
          Het internet is noodzakelijk voor de monitoring: dit houdt in dat jij en Solease kunnen inzien wat je
          zonnestroomsysteem opwekt. Voor de monitoring heeft het onze voorkeur om een kabel te trekken via de meterkast
          naar de router om een stabiele en betrouwbare verbinding te leggen tussen het zonnestroomsysteem en onze
          monitoring. Indien er geen gebruik gemaakt wordt van een kabel, zal er verbinding gemaakt worden via een WiFi
          module. Je bent in dat geval zelf verantwoordelijk voor een goede WiFi verbinding met de omvormer.
        </Description>
        {
          descFolded ?
            <FoldButton onClick={() => setDescFolded(!descFolded)}>
              <p>Lees verder</p>
              Uitklappen
              <img alt="" src={foldArrow} />
            </FoldButton> :
            <FoldButton onClick={() => setDescFolded(!descFolded)}>
              Inklappen
              <img alt="" src={foldArrow} style={{ transform: 'scaleY(-1)' }} />
            </FoldButton>
        }
        <ButtonsContainer>
          <Button onClick={(e)=>{
            e.preventDefault()
            history.push('/akkoord/betaalwijze')
          }}
          >VORIGE STAP</Button>
          <Button onClick={(e)=>{
            e.preventDefault()
            history.push('/akkoord/photo-postpone')
          }}
          >Ik heb nu geen foto’s <br /> Ga naar afronden </Button>
          <Button
            main
            disabled={formState.isSubmitting}
            onClick={handleSubmit((data) => {
              onSubmit({
                data: {
                  fields: {
                    router_location: data.routerLocationOther ? data.routerLocationOther : data.router_location,
                    router_freeport: data.router_freeport
                  }, images: [...meterkastImages, ...meterImages, ...fuseImages]
                },
                route: 'afronding',
                postRoute: 'submit/photos',
                form: 2
              })
            })}
          >volgende: <br /> MEterkast foto’s <br /> uploaden</Button>
        </ButtonsContainer>
      </StyledForm>
    </Wrapper>
  )
}

export default Step4
