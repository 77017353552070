import Header from '../../Header'
import styled from 'styled-components'

const Section = styled.div`

  margin: auto;
  width: 90%;

  p:nth-child(1) {
    font-weight: bold;
  }

  p:last-child {
    font-size: 14px;
    font-style: italic;
  }

  img {
    width: 200px;
  }
`

const BackgroundImage = styled.img`
  height: 140px;
  position: absolute;
  top: -20px;
  right: 0;
`

const Step3Confirmation = ({ onSubmit }) => {

  const pierreImage = require('../../../../assets/images/Pierre.png').default
  const soleaseLogo = require('../../../../assets/images/SoleaseLogo.png').default

  return (
    <div>
      <BackgroundImage alt="" src={soleaseLogo} />
      <Header title="Gefeliciteerd, je bent klant!" />
      <Section>
        <p>Welkom bij Solease!</p>
        <p>Nogmaals bedankt voor het accepteren van de offerte! Hierbij bevestigen we de overeenkomst, bestaande uit je
          akkoord op de offerte, de digitale machtiging en de algemene voorwaarden. *</p>
        <p>Binnenkort ga jij je zonne-energie opwekken. Geweldig dat we er weer een nieuwe zonne-energie ambassadeur bij
          hebben!</p>
        <p><br />Zonnige groet,</p>
        <p>Pierre Vermeulen, <br />
          oprichter en CEO van Solease </p>
        <img alt="" src={pierreImage} />
        <p>* De 14 dagen bedenktijd gaan nu in, gedurende deze periode kun je de overeenkomst nog kosteloos
          ontbinden.</p>
      </Section>

    </div>
  )
}

export { Step3Confirmation }
