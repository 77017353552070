import styled from 'styled-components'
import Loader from 'react-loader-spinner'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  grid-area: main;
  justify-content: center;
`

const Loading = () => {
  return (
    <Wrapper>
      <Loader type="TailSpin" color="#00BFFF" height={80} width={80} />
    </Wrapper>
  )
}

export default Loading
