import { createStore, StateMachineProvider } from "little-state-machine";
import styled from "styled-components/macro";
import AcceptForm from "../AcceptForm/components/AcceptForm";
import Navigation from "../AcceptForm/components/Navigation";
import NavigationItem from "../AcceptForm/components/NavigationItem";

const Layout = styled.div`
  display: grid;
  grid-template-columns: auto 320px minmax(auto, 960px) auto;
  grid-template-rows: 100px auto;
  grid-template-areas:
    ". header main ."
    ". nav main .";
  grid-gap: 24px;
  margin-top: 24px;
  min-height: 820px;
  max-height: 1000px;
  
  @media (max-width: 1300px){
    display: flex;
    flex-direction: column;
  }
  @media(max-width: 600px){
    display: block;
    min-height: unset;
    margin-top: 0;
  }
`;

const LogoWrapper = styled.div`
  grid-area: header;
  padding: 10px 20px;
  
  @media (max-width: 1300px){
    width: 300px;
  }

  @media(max-width: 600px){
    grid-area: unset;
    width: 128px;
    height: 32px;
    padding: 0;
    margin-top: 46px;
    margin-left: 32px;
  }
`;

const Logo = styled.img`
  max-width: 100%;
`;

function log(store) {
  return store;
}

createStore({ data: {}, loading: false }, { middleWares: [log] });

const navItems = [
  { name: "Gegevens", route: "/akkoord/gegevens" },
  { name: "Samenvatting", route: "/akkoord/samenvatting" },
  { name: "Betaalwijze", route: "/akkoord/betaalwijze" },
  { name: "Meterkast", route: "/akkoord/meterkast" },
  { name: "Afronding", route: "/akkoord/afronding" }
];

const AcceptFormPage = () => {
  return (
    <StateMachineProvider>
      <Layout>
        <LogoWrapper>
          <Logo src="/logo.png" alt="Solease logo" />
        </LogoWrapper>
        <Navigation>
          {navItems.map((item) => (
            <NavigationItem key={item.name} {...item} />
          ))}
        </Navigation>
        <AcceptForm />
      </Layout>
    </StateMachineProvider>
  );
};

export default AcceptFormPage;
