import styled from 'styled-components/macro'
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch
} from 'react-router-dom'
import { useStateMachine } from 'little-state-machine'
import { useMemo, useEffect } from 'react'

import Step1 from './steps/Step1'
import Step2 from './steps/Step2'
import Step4 from './steps/Step4'
import Finish from './steps/Finish'
import Step5 from './steps/Step 5'
import { useState } from 'react'
import PhotoPostpone from './steps/PhotoPostpone'
import Step3A from './steps/Step3/Step3A'
import Loading from './Loading'
import Step3B from './steps/Step3/Step3B'
import LoginFailed from './LoginFailed'

const StyledSteps = styled.main`
  max-width: 960px;
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  padding: 36px 40px;
  grid-area: main;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #fff;
  @media(max-width: 1300px){
    margin: auto;
    width: 90%;
  }
  @media(max-width: 600px){
    padding: 0;
    width: 90%;
    margin: auto;
    box-shadow: none;
  }
`

const updateData = (state, payload) => {
  if (typeof payload.birthdate === 'object') {
    payload.birthdate = new Date(
      payload.birthdate.getTime() -
      payload.birthdate.getTimezoneOffset() * 60000
    ).getTime()
  }

  return {
    ...state,
    data: {
      ...state.data,
      ...payload
    }
  }
}

const setLoading = (state, payload) => ({
  ...state,
  loading: payload
})

const AcceptForm = () => {
  const { path } = useRouteMatch()
  const history = useHistory()
  const location = useLocation()
  const parameters = useMemo(() => new URLSearchParams(location.search), [
    location.search
  ])
  const { actions, state } = useStateMachine({ updateData, setLoading })
  const [loginData, setLoginData] = useState()
  const [tokenState, setTokenState] = useState()
  const [loginSuccess, setLoginSuccess] = useState(true)
  const [mandateInfo, setMandateInfo] = useState({
    status: null,
    trxid: null
  })

  useEffect(() => {
    actions.updateData({ ...Object.fromEntries(parameters) })
    history.replace({ search: '' })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const post = async (body, route, onSuccess) => {
    actions.setLoading(true)
    await fetch(
      `https://proxy.staging.solease.nl/test/paf/${route}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': loginData?.token && loginData.token
        },
        body
      }
    ).then(response => onSuccess && response.json())
      .then(onSuccess)
  }

  const onSubmit = async ({ data, route, postRoute, form }) => {
    if (form === 3) {
      actions.updateData({ ...data, step3Finished: true })
    } else {
      actions.updateData(data)
      let dataBuffer = {}
      if ('images' in data) dataBuffer = data
      else dataBuffer = { fields: data }
      if (postRoute) await post(JSON.stringify(dataBuffer), postRoute)
      console.log(route)
      console.log(tokenState)
      actions.updateData(data)
      route && getState()
      //history.push(`${path}/${route}`)
      actions.setLoading(false)
    }
  }

  const login = () => {
    const cus = localStorage.getItem('cus')
    const est = localStorage.getItem('est')
    if(cus && est){
      if(parameters.get('trxid')){
        setMandateInfo({
          status: parameters.get('status'),
          trxid: parameters.get('trxid')
        })
      }
      const body = {
        cus,
        est
      }
      post(JSON.stringify(body), 'login', (data) => {
        setLoginData(data)
        actions.setLoading(false)
      })
    } else{
      setLoginSuccess(false)
      actions.setLoading(false)
    }
  }
  const getState = () => {
    if (loginData) {
      fetch(mandateInfo.trxid ? `https://proxy.staging.solease.nl/test/paf/state?transactionId=${mandateInfo.trxid}` : 'https://proxy.staging.solease.nl/test/paf/state', {
        method: 'GET',
        headers: {
          'Authorization': loginData.token
        }
      }).then(response => response.json())
        .then(data => {
          if(data.status === 'Success'){
            setTokenState(["payment"])
            actions.updateData({ ...state.data, step3Finished: true })
          }else if(data.status === 'Cancelled'){
            console.log('transaction failed')
            history.push('/akkoord/betaalwijze')
          }else if(data.submitted){
            setTokenState(data.submitted)
          }
        })
    }
  }

  const setRoute = () => {
    if (tokenState) {
      const lastItem = tokenState ? tokenState[tokenState.length - 1] : null
      !tokenState ? history.push('/akkoord/gegevens') :
        lastItem === 'info' ? history.push('/akkoord/samenvatting') :
          lastItem === 'summary' ? history.push('/akkoord/betaalwijze') :
            lastItem === 'payment' ? history.push('/akkoord/meterkast') :
              lastItem === 'photos' && history.push('/akkoord/afronding')
    }
  }
  useEffect(login, []) // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(getState, [loginData]) // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(setRoute, [tokenState]) // eslint-disable-line react-hooks/exhaustive-deps

  if (state.loading || !tokenState) return <Loading />
  else if (!loginSuccess) return <LoginFailed />
  else return (
    <StyledSteps>
      <Switch>
        <Route
          exact
          path={`${path}/gegevens`}
          component={() => (
            <Step1 state={state} onSubmit={onSubmit} parameters={parameters} loginData={loginData} />
          )}
        />
        <Route
          exact
          path={`${path}/samenvatting`}
          component={() => (
            <Step2 state={state} onSubmit={onSubmit} parameters={parameters} data={loginData} />
          )}
        />
        <Route
          exact
          path={`${path}/betaalwijze`}
          component={() => loginData.rent ?
            <Step3A state={state} estimateNumber={loginData.estimateNumber}
                    setLoading={()=>actions.setLoading(true)}
                    finished={(tokenState && tokenState.includes('payment')) || mandateInfo.status === 'Success'}
                    onSubmit={onSubmit} parameters={parameters}
                    token={loginData.token} price={loginData.price} /> :
            <Step3B state={state} onSubmit={onSubmit} parameters={parameters} />
          }
        />
        <Route
          exact
          path={`${path}/photo-postpone`}
          component={() => (
            <PhotoPostpone state={state} onSubmit={onSubmit} />
          )}
        />
        <Route
          exact
          path={`${path}/meterkast`}
          component={() => (
            <Step4 state={state} onSubmit={onSubmit} parameters={parameters} />
          )}
        />
        <Route
          exact
          path={`${path}/afronding`}
          component={() => (
            <Step5 rent={loginData.rent} survey={loginData.survey}
                   state={state} onSubmit={onSubmit} parameters={parameters} />
          )}
        />
        <Route
          exact
          path={`${path}/verzonden`}
          component={() => <Finish parameters={parameters} />}
        />
      </Switch>
    </StyledSteps>
  )
}

export default AcceptForm
