import styled, { css, keyframes } from 'styled-components'
import Dropzone from 'react-dropzone-uploader'
import 'react-dropzone-uploader/dist/styles.css'
import { useEffect, useState } from 'react'

const AnimateBorder = keyframes`
  from {
    border-left: 4px solid #5FBADE;
  }
  25% {
    border-top: 4px solid #5FBADE;
  }
  50% {
    border-right: 4px solid #5FBADE;
  }

  75% {
    border-bottom: 4px solid #5FBADE;
  }
`


const Wrapper = styled.div`
  width: 562px;

  p:nth-child(1) {
    margin: 0;
  }

  .dzu-dropzone {
    min-height: 146px;
    background-color: rgba(225, 225, 225, .4);
    border: none;
    padding: 4px;
  }

  .dzu-submitButton {
    display: none;
  }

  .dzu-previewContainer {
    border-bottom-color: rgba(0, 0, 0, .1);
  }

  .dzu-previewImage {
    cursor: pointer;
  }

  .dzu-previewContainer {
    padding: 5px 20px;
  }

  @media(max-width: 600px) {
    width: 230px;
  }
`

const InputComponent = styled('label')`
  text-align: center;
  margin-top: 16px;
  margin-bottom: 10px;
  
  .desktop{
    p {
      color: #7E7E7E;
    }

    div {
      color: black;
      width: 284px !important;
      height: 56px;
      background-color: rgba(225, 225, 225, .6);
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 4px solid transparent;

      ${props => props.animateBorder && css`
      animation: ${AnimateBorder} infinite;
      animation-duration: 2s;
    `}
      &:active {
        transform: scale(1.1);
      }
    }
  }

  input {
    display: none;
  }
  
  .mobile{
    display: none;
  }

  @media(max-width: 600px) {
    height: 108px;
    width: 208px;
    .desktop{
      display: none;
    }
    .mobile{
      display: block;
      border: 2px solid #C4C4C4;
      border-radius: 10px;
      width: 80%;
      margin: auto;
      
      height: 100%;
      div{
        margin-top: 10px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        height: 60px;
        img{
          width: 60px;
        }
      }
      p{
        margin: 0;
      }
    }
    
  }
`

const FileInput = (props) => {

  //find solution to border animation that doesn't fck up file input onchange event
  const [borderAnimation, setBorderAnimation] = useState(false)

  const cameraIcon = require('../../assets/images/camera.svg').default
  const uploadIcon = require('../../assets/images/upload.svg').default

  useEffect(() => {
    borderAnimation && setTimeout(() => setBorderAnimation(false), 2000)
  }, [borderAnimation])

  const convertAndPass = (file) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = function() {
      const type = file.type.split('/')[1]
      const base64 = reader.result.split(/,(.+)/)[1]
      props.passImage(base64, type)
    }
    reader.onerror = function(error) {
      console.log('Error: ', error)
    }
  }

  // called every time a file's `status` changes
  const handleChangeStatus = ({ meta, file }, status) => {
    status === 'done' && convertAndPass(file)
    const images = document.getElementsByClassName('dzu-previewImage')
    for (let image of images) {
      image.addEventListener('click', () => props.activatePopup(image.src))
    }
  }

  const loadInputComponent = ({ accept, onFiles, getFilesFromEvent }) => {
    return <InputComponent animateBorder={borderAnimation}>
      <div className='mobile'>
        <div>
          <img alt='' src={uploadIcon}/>
          <img alt='' src={cameraIcon}/>
        </div>
        <p>Selecteer bestanden</p>
      </div>
      <div className='desktop'>
        <p>Sleep bestanden hierheen of</p>
        <div>Selecteer bestanden</div>
      </div>
        <input
          type="file"
          accept={accept}
          multiple
          onChange={e => onFiles(getFilesFromEvent(e))}
        />
    </InputComponent>
  }

  return (
    <Wrapper>
      {props.title}
      <Dropzone
        onChangeStatus={handleChangeStatus}
        InputComponent={loadInputComponent}
        inputWithFilesContent="Sleep bestanden hierheen of Selecteer bestanden"
        autoUpload={false}
        accept="image/png, image/jpg, image/jpeg" />
    </Wrapper>
  )
  }

  export
    {
      FileInput
    }
