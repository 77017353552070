import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  grid-area: main;
  justify-content: center;
`

const Loading = () => {
  return (
    <Wrapper>
      <p>SOMETHING WENT WRONG WITH LOADING YOUR ACCEPT FORM</p>
    </Wrapper>
  )
}

export default Loading
