import styled from "styled-components/macro";

const StyledNavigation = styled.nav`
  position: relative;
  grid-area: nav;
  display: flex;
  flex-direction: column;
  gap: 60px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  border-radius: 48px 4px 4px 48px;
  background: #fff;
  padding-top: 80px;
  padding-left: 10px;
  
  &:after{
    content: '';
    height: 660px;
    position: absolute;
    right: 30px;
    top: 20px;
    width: 2px;
    background-color: #FFDB00;
  }
  
  @media(max-width: 1300px){
    max-width: 960px;
    flex-direction: row;
    border: none;
    box-shadow: none;
    height: 100px;
    width: 90%;
    margin: auto;
    
    &:after{
      height: 2px;
      width: 85%;
      right: 50%;
      top: unset;
      bottom: 47px;
      transform: translateX(50%);
    }
  }
`

const Navigation = ({ children }) => {
  return <StyledNavigation>{children}</StyledNavigation>
}

export default Navigation
