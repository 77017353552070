import { forwardRef } from "react";
import styled from "styled-components/macro";
import Error from "./Error";

const StyledCheckbox = styled.input`
  margin: 16px;
  transform: scale(1.4);
`;

const CheckboxContainer = styled.div`
  grid-column: span 2;
  margin-right: auto;
  @media(max-width: 600px){
    ${StyledCheckbox}{
      margin: 0;
      margin-right: 5px;
    }
  }
`;

const Checkbox = forwardRef(({ name, label, errors, link }, ref) => (
  <>
    <CheckboxContainer>
      <StyledCheckbox name={name} id={name} type="checkbox" ref={ref} />
      <label htmlFor={name}>{label}</label>
      {link}
      <Error errors={errors?.[name]} />
    </CheckboxContainer>
  </>
));

export default Checkbox;
